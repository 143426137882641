.customer-page-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .customer-page-list {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 100%;
    overflow: hidden;

    .row {
      min-height: 80px;
    }

    .avatar {
      width: 60px;
      height: 60px;
    }

    .action {
      display: flex;
      justify-content: center;
    }

    .toggle-active-technician-btn {
      background: inherit;
      text-decoration: underline;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .customer-page-paging {
    width: 100%;
    padding: 40px 0;
  }

  .havesort {
    display: flex;
    align-items: center;
  }
  .sort-btns {
    display: flex;
    flex-direction: column;
    margin-right: 4px;
  }

  @media screen and (max-width: 1024px) {
    .customer-page-list {
      width: calc(100vw - 30px);
      overflow-x: scroll;

      .list {
        min-width: 864px;
      }
    }
  }
}

.customer-modal {
  form {
    display: flex;
    flex-direction: column;
  }

  .customer-modal-basic-info {
    margin-top: 16px;
    padding-bottom: 72px;

    .row {
      flex-wrap: wrap;
      align-items: center;
      align-items: flex-start;
    }

    .label-input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 10px 10px;
      padding: 0 20px;

      .label-description {
        font-weight: 400;
        font-size: 12px;
      }

      &.avatar {
        .input-avatar {
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            width: 70px;
            height: 70px;
            border-radius: 15px;
          }

          span {
            margin-left: 18px;
            cursor: pointer;
            color: var(--primary-text-color);
            text-decoration: underline;
            font-family: 'Poppins-Medium';
            font-size: 12px;
          }
        }
      }

      &.spec {
        margin-bottom: 20px;
      }

      &.col {
        margin-top: 20px;
      }

      label {
        color: var(--primary-text-color);
        font-size: 12px;
        font-family: 'Poppins-Medium';
        min-width: 190px;
        margin-top: 15px;
      }

      input,
      select {
        height: 45px;
        width: 450px;

        &:disabled {
          border-radius: 5px;
        }
      }

      .role {
        .ant-select-selector {
          height: 45px;
          width: 450px;
        }
      }

      .phone-detail {
        width: 450px;
        display: flex;
        gap: 24px;
        align-items: center;

        &-code {
          width: 120px;
        }

        &-number {
          width: 100%;
        }

        .ant-select-selector {
          height: 45px;
          width: 120px;
        }
      }

      .label-with-subtract {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 2px;

        img {
          cursor: pointer;

        }
      }

      textarea {
        border-radius: 5px;
        padding: 5px 10px;
        min-height: 60px;
        max-width: 450px;
        width: 450px;
      }

      .input-password {
        display: flex;
        flex-direction: column;
        position: relative;

        img {
          position: absolute;
          top: 15px;
          right: 20px;
          width: 20px;
          cursor: pointer;
        }
      }

      &.input-password-area {
        width: 100%;

        .input-area {
          position: relative;
          width: 450px;

          input {
            width: 450px;
          }

          .input-password-show-btn {
            position: absolute;
            cursor: pointer;
            right: 12px;
            top: 12px;
            width: 20px;
            height: 15px;

            &.display {
              top: 10px;
              right: 10px;
              width: 20px;
              height: 11px;
            }
          }
        }
      }

      .password-extra {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: var(--primary-text-color);
        font-size: 12px;
        font-family: 'Poppins-Regular';

        .generate {
          color: var(--primary-text-color);
          cursor: pointer;
          text-decoration: underline;
        }
        .copy {
          cursor: pointer;
        }
      }
    }
  }

  .customer-modal-basic-address {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .col {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        justify-content: space-between;
        color: var(--primary-text-color);
        padding-left: 5px;

        &:first-child {
          margin-right: 40px;
        }
      }

      .header-title {
        color: var(--primary-text-color);
        font-family: 'Poppins-Bold';
        font-size: 16px;
      }

      .same-address {
        color: var(--primary-text-color);
        font-family: 'Poppins-Regular';
        font-size: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;

        input[type='checkbox'] {
          margin-left: 12px;
          width: 22px;
          height: 22px;
        }
      }
    }
    .input-area {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .col {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;

        &:first-child {
          margin-right: 40px;
        }

        input,
        select {
          width: 100%;
          height: 45px;
          border-radius: 5px;
          margin-top: 12px;
          padding: 0 20px;

          &:disabled {
            background-color: var(--primary-disable-color);
          }
        }

        select {
          padding: 0 14px;
        }

        .select {
          width: 100%;
          margin-top: 12px;
          .ant-select-selector {
            width: 100%;
            height: 45px;
            border-radius: 5px;
            padding: 0 20px;
          }
        }
      }
    }
  }

  .customer-btn-bottom-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .create-customer-btn {
    width: 255px;
    height: 36px;
    margin-top: 12px;
    margin-left: auto;
    margin-right: 72px;
    background-color: var(--primary-btn-color);
    border-radius: 5px;
    border: none;
    font-size: 11px;
    font-family: 'Poppins-Medium';
    color: var(--primary-btn-text-color);
  }

  .edit-customer-btn {
    font-size: 11px;
    font-family: 'Poppins-Medium';
    color: var(--primary-text-color);
    text-decoration: underline;
    padding-right: 30px;
    cursor: pointer;
  }

  @media screen and(max-width: 1024px) {
    width: 94vw;
  }
}

.booking-order {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--table-text-color);
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;

  .title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 10px;
    background-color: var(--background-2);

    label {
      width: 100%;
      font-weight: 600;
      font-size: 16px;
    }

    .booking-types {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      color: var(--table-text-color);

      .item {
        padding: 5px 10px 5px 0;
        cursor: pointer;
      }

      .selected {
        color: var(--table-text-color);
        font-weight: 600;
        text-decoration: underline;
      }
    }

    .download-csv {
      margin-left: auto;
      cursor: pointer;
      border: none;
      outline: none;
      padding: 0;
      background: transparent;
      text-decoration: underline;
      width: 240px;
      text-align: right;
    }

    .downloading-csv {
      margin-left: auto;
      margin-right: 70px;
    }
  }

  &-filterbar {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 8px;
  }

  .list {
    width: 100%;

    .last {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      text-align: right;
    }

    .icon {
      cursor: pointer;
      background-color: var(--background-3);
      max-height: 22px;
      max-width: 22px;
      height: 22px;
      width: 22px;
      border-radius: 11px;
      color: var(--background-1);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}

.customer-address {
  &__detail {
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 8px;
  }

  &__add {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: var(--primary-text-color);
  }

  &__same-content {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    input {
      margin-right: 12px;
      width: 16px;
      height: 16px;
    }
  }

  &-header {
    background: var(--table-header-color-1);
    color: var(--table-header-text-1);
    display: flex;
    justify-content: space-between;
    padding: 6px 12px;
    align-items: center;
  }

  &-content {
    padding: 6px 12px;
    position: relative;
    color: black;

    &-edit {
      cursor: pointer;
      width: 50px;
      height: 50px;
      position: absolute;
      right: 24px;
      top: 12px;
      background-image: url('../images/edit-icon.png');
    }

    &-edit.none-icon {
      background-image: none;
      width: auto;
    }

    label {
      font-weight: bold;
    }

    p {
      margin: 0;
    }
  }

  &-btn {
    border-radius: 8px;
    padding: 4px 16px;
    background: var(--secondary-btn-color);
    color: var(--secondary-btn-text-color);
    outline: none;
    border: none;
    width: 240px;
  }

  .empty-primary-message {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: black;
  }
}

.customer-address-detail-modal {
  padding: 16px;

  form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;

    label {
      font-weight: bold;
      color: var(--primary-text-color);
    }

    input,
    select {
      display: block;
      width: 450px;
      height: 45px;
      border-radius: 5px;
      padding: 0 20px;
    }

    .select {
      display: block;
      .ant-select-selector {
        width: 450px;
        height: 45px;
        border-radius: 5px;
      }
    }
  }

  &-btn {
    width: 255px;
    height: 36px;
    margin-top: 32px;
    background-color: var(--primary-btn-color);
    color: var(--primary-btn-text-color);
    border-radius: 5px;
    border: none;
    font-size: 11px;
    font-family: 'Poppins-Medium';
  }

  &-setdefault {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: var(--primary-text-color);
  }
}

.customer-address-list-modal {
  padding: 16px;

  &-addnew {
    display: flex;
    justify-content: center;

    button {
      border-radius: 8px;
      padding: 4px 16px;
      outline: none;
      height: 36px;
      background-color: var(--primary-btn-color);
      color: var(--primary-btn-text-color);
      border: none;
    }
  }

  .customer-address-general {
    position: relative;
    min-width: 576px;
    border-radius: 8px;
    border: 1px solid var(--border-color-1);
    background: var(--background-3);
    margin-bottom: 16px;
    overflow: hidden;

    &__primarylabel {
      position: absolute;
      right: -8px;
      top: 0;
      border-bottom-left-radius: 12px;
      background: var(--background-2);
      padding: 0 24px;
      color: var(--primary-text-color);
    }

    &__content {
      padding: 6px 12px;

      label {
        font-weight: bold;
      }

      p {
        margin-bottom: 4px;
      }
    }

    &__action {
      padding: 6px 12px;
      border-top: 1px solid var(--border-color-1);
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      button {
        border-radius: 8px;
        padding: 4px 16px;
        outline: none;
        width: 120px;
        height: 36px;
      }

      &--del {
        background: var(--secondary-btn-color);
        color: var(--color-1);
        border: 1px solid var(--color-1);
      }

      &--edit {
        background-color: var(--primary-btn-color);
        color: var(--primary-btn-text-color);
        border: none;
      }
    }
  }
}
