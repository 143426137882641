@import '~antd/dist/antd.css';

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'), url('./fonts/Poppins/Poppins-Bold.ttf') format('woff');
}

@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins-Light'), url('./fonts/Poppins/Poppins-Light.ttf') format('woff');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'), url('./fonts/Poppins/Poppins-Medium.ttf') format('woff');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'), url('./fonts/Poppins/Poppins-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'), url('./fonts/Poppins/Poppins-SemiBold.ttf') format('woff');
}

button {
  cursor: pointer;
}

@import url('./css/modal.scss');

* {
  box-sizing: border-box;
}

.overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .loading-message {
      background-color: rgba(100, 100, 100, 0.5);
      width: 400px;
      height: 200px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 600;
    }
  }
}

body {
  margin: 0;
  font-family: 'Poppins-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #e2eef8;

  #root {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    .application {
      max-width: 1300px;
      margin: 0 auto;
    }
  }
}

.btn-primary {
  background-color: #dc3d21;
  color: white;
  border: none;
}

@media only screen and (max-width: 1300px) {
  body {
    justify-content: left;
  }
}

.input-value {
  height: 56px;
  border-radius: 10px;
  font-size: 14px;
  padding: 0 5px;
  border: none;
}

.input-error {
  border: 1px solid #dc3d21 !important;
  border-radius: 5px;
}

.input-require {
  color: #dc3d21;
}

.input-password-area {
  position: relative;

  .input-password-show-btn {
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 40px;
    width: 27px;
    height: 20px;

    &.display {
      top: 44px;
      right: 25px;
      width: 20px;
      height: 11px;
    }
  }
}

.application {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .application-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 30px;

    .main-content {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      height: 100%;
    }

    @import url('./css/left-nav.scss');

    @import url('./css/login.scss');
    @import url('./css/version-popup.scss');
    @import url('./css/confirm-otp.scss');

    @import url('./css/forgot-password.scss');

    .site-page {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
      width: 100%;

      .site-page-header {
        width: 100%;
        height: 82px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-family: 'Poppins-SemiBold';
        color: var(--color-1);
        position: relative;
        padding: 8px;

        .cancel-annouce {
          color: var(--down);
        }

        .site-page-header-right {
          display: flex;
          flex-direction: row;
          align-items: center;

          .extra-link {
            text-decoration: underline;
            cursor: pointer;
            font-size: 12px;
            font-family: 'Poppins-Medium';
            margin-right: 42px;
            margin-top: 20px;
          }

          button {
            width: 255px;
            height: 36px;
            background-color: var(--background-1);
            color: black;
            font-family: 'Poppins-Medium';
            font-size: 11px;
            border-radius: 5px;
            border-width: 0;
            color: white;
          }

          .search-input-area {
            position: relative;
            margin-left: 17px;

            .search-input {
              font-family: 'Poppins-Regular';
              font-size: 14px;
              width: 260px;
              height: 36px;
              border: var(--color-1) 1px solid;
              border-radius: 5px;
              padding: 15px 35px 15px 15px;
              background-color: white;

              color: #4f4f4f;

              ::placeholder {
                color: white;
              }
            }

            .search-icon {
              position: absolute;
              right: 15px;
              top: 13px;
            }
          }
        }

        @import url('./css/schedule-header.scss');
      }

      .site-page-export {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 8px;

        .export-link-btn {
          text-decoration: underline;
          cursor: pointer;
          color: var(--primary-text-color);
        }
      }

      .site-page-content {
        position: relative;
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 12px;
        padding: 8px;

        &.cancel {
          input,
          select {
            pointer-events: none;
            background: var(--primary-disable-color);
          }
          .add-vo {
            pointer-events: none;
            background: var(--primary-disable-color);
          }
        }

        @import url('./css/dashboard.scss');
        @import url('./css/setting.scss');

        @import url('./css/customer.scss');

        @import url('./css/service.scss');
        @import url('./css/banner.scss');
        @import url('./css/feedback.scss');
        @import url('./css/notification.scss');
        @import url('./css/booking.scss');
        @import url('./css/quote.scss');
        @import url('./css/note.scss');
        @import url('./css/schedule.scss');
        @import url('./css/finance-report.scss');
        @import url('./css/task.scss');
        @import url('./css/invoice.scss');
        @import url('./css/component.scss');
        @import url('./css/pdf.scss');
        @import url('./css/promote.scss');
      }

      .site-page-filter {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 4px 0;
      }
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  max-height: 100%;

  .row {
    &:nth-child(2n + 1) {
      background-color: var(--background-1);
    }

    &:nth-child(2n) {
      background-color: var(--background-2);
    }

    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px 15px;
    align-items: center;
    gap: 4px;

    .column {
      :first {
        margin-left: 0;
      }

      &.action {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      a {
        color: var(--table-text-color);
        text-decoration: underline;
      }

      margin-left: 5px;
      color: var(--table-text-color);
      font-size: 12px;
      font-family: 'Poppins-Medium';
    }

    .edit-btn {
      cursor: pointer;
      width: 50px;
      height: 50px;
      background-image: url('./images/edit-icon.png');
    }

    .del-btn {
      cursor: pointer;
      width: 50px;
      height: 50px;
      background-image: url('./images/delete-icon.png');
    }
  }

  .header {
    background-color: var(--background-1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    padding: 18px 15px;
    color: var(--table-text-color);
    gap: 4px;

    span {
      font-size: 12px;
      font-family: 'Poppins-Medium';
    }
  }
}

.f1 {
  flex: 1;
}

.f2 {
  flex: 2;
}

.f3 {
  flex: 3;
}

.f4 {
  flex: 4;
}
.f5 {
  flex: 5;
}
.f6 {
  flex: 6;
}
.f7 {
  flex: 7;
}
.f8 {
  flex: 8;
}
.f9 {
  flex: 9;
}
.f10 {
  flex: 10;
}
.f11 {
  flex: 11;
}

.dfc {
  display: flex;
  flex-direction: column;
}

.dfr {
  display: flex;
  flex-direction: row;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2.2px;

  &.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}

.padding-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .padding-item {
    width: 20px;
    height: 20px;
    background-color: white;
    color: #444444;
    font-family: 'Montserrat-Medium';
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 7px;
    border-radius: 99px;
    cursor: pointer;

    &.selected {
      background-color: var(--background-2);
      color: white;
    }

    &.disabled {
      background-color: rgba(28, 38, 133, 0.5);
      cursor: auto;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
}

select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

select.minimal:focus {
  background-image: linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}

.send-detail-btn {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 8px;
  justify-content: flex-end;
  align-items: center;

  button {
    width: 250px;
    height: 40px;
    padding: 8px 0px;
    border-radius: 5px;

    &.primary {
      background-color: var(--primary-btn-color);
      border: none;
      color: #ffffff;

      &:hover:not(:disabled) {
        opacity: 0.9;
      }
    }
  }
}

.action-button {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 16px 0;
  justify-content: space-evenly;
  align-items: center;

  button {
    width: 250px;
    height: 40px;
    padding: 8px 0px;
    border-radius: 5px;

    &.primary {
      background-color: var(--background-1);
      border: none;
      color: #ffffff;
    }

    &.secondary {
      background-color: white;
      border: 1px solid var(--color-1);
      color: var(--color-1);

      &:disabled {
        background-color: var(--primary-disable-color);
        color: #7e7979;
        border-color: #7e7979;
      }
    }

    &.tertiary {
      background-color: #2aaa8a;
      border: none;
      color: #ffffff;
    }
  }

  @media screen and(max-width: 1024px) {
    button {
      width: auto;
      padding: 8px 16px;
    }
  }
}

button {
  &:hover:not(:disabled) {
    text-decoration: underline;
  }
}

// .date-time-picker {
//   background-color: #ffff;
//   border-radius: 5px;
//   color: #313131;
//   padding: 4px;

//   .react-date-picker__wrapper {
//     border: none;
//     background-color: white;
//     height: 36px;
//     align-items: center;
//     border-radius: 5px;
//   }
// }

.date-time-picker {
  padding: 0px 4px;
  border-radius: 5px;
  input {
    background-color: #ffff;
    border-radius: 5px;
    color: #313131;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 30px;
    border-radius: 5px;
  }
}

.react-timerange-picker {
  background-color: #ffff;
  border-radius: 5px;
  color: #313131;

  .react-timerange-picker__wrapper {
    border: none;
    background-color: white;
    height: 36px;
    align-items: center;
    border-radius: 5px;

    .react-timerange-picker__inputGroup {
      height: unset;
    }
  }
}

.label-input {
  &.center {
    input {
      text-align: center;
    }
  }
}

.new-booking {
  background-color: #15a4c6;
  color: black;
}
.re-assigned {
  background-color: #e24b4b;
}
.assigned {
  background-color: #aeaeae;
  color: black;
}
.on-the-way {
  background-color: #b2c121;
  color: black;
}
.task-started {
  background-color: #57ac57;
}
.completed {
  background-color: #81453b;
}
.notes {
  background-color: #363636;
}

.canceled {
  background-color: gray;
}

.tac {
  text-align: center;
}

.ant-select-selection-placeholder {
  color: gray;
}

input {
  border: 1px solid var(--border-color-1);
}

.ant-picker-input > input:placeholder-shown,
.ant-picker-input > input {
  font-size: 12px;
}

.Dropdown-disabled {
  background: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-input:placeholder-shown,
.ant-input,
.ant-select {
  font-size: 12px;
}

.table-search-area {
  position: relative;
  margin-left: 17px;

  .table-search {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    width: 260px;
    height: 28px;
    border-radius: 2px;
    padding: 15px 35px 15px 15px;
    background-color: white;
    border: none;

    color: #4f4f4f;

    ::placeholder {
      color: white;
    }
  }

  .search-icon {
    position: absolute;
    right: 15px;
    top: 10px;
  }
}

.hide-component {
  opacity: 0;
}
