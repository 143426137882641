.dashboard-page {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media screen and (max-width: 1310px) {
    flex-direction: column;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    background-color: var(--dashboard-background);
    border-radius: 10px;

    .frequency-area {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 10px;

      .frequency-content {
        display: flex;
        flex-direction: row;
        align-items: center;

        background-color: var(--background-3);
        border-radius: 16px;
        border: 1px solid var(--color-1);
        height: 40px;
        width: 200px;
        padding: 3px 6px;

        .frequency-item {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: 100%;
          cursor: pointer;

          span {
            font-size: 10px;
            font-weight: 700;
            color: var(--secondary-text-color);
          }
          &.selected {
            background-color: var(--background-1);
            border-radius: 16px;

            span {
              color: var(--primary-btn-text-color);
            }
          }
        }
      }
    }

    .report-sale-task-customer {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 8px;

      .sale,
      .task,
      .customer {
        flex: 1;
        border-radius: 5px;
        padding: 15px;
        background-color: var(--dashboard-summary-background);
        margin-left: 10px;
        border: var(--dashboard-summary-border);

        display: flex;
        flex-direction: row;
        align-items: center;

        .up-down-icon {
          width: 35px;
          height: 35px;
          border-radius: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          &.up {
            background-color: var(--up-alpha-20);
          }
          &.down {
            background-color: var(--down-alpha-20);
          }

          .arrow-up {
            color: var(--up);
          }

          .arrow-down {
            color: var(--down);
          }
        }

        .report-content {
          display: flex;
          flex-direction: column;
          width: 100%;
          flex: 1;
          color: var(--dashboard-summary-color-text);
          margin-left: 10px;

          .current-value {
            font-size: 18px;
            font-weight: 700;
          }

          .report-item-info {
            font-size: 10px;
            font-weight: 600;

            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .up {
              color: var(--up);
            }

            .down {
              color: var(--down);
            }
          }
        }
      }

      :nth-child(1) {
        margin-left: 0px;
      }
    }

    .chart-area {
      margin-top: 30px;
      padding: 10px;
      .chart-header {
        display: flex;
        flex-direction: row;
        align-items: center;

        .title {
          color: var(--primary-text-color);
          font-size: 18px;
          font-weight: 700;
        }

        .ant-picker {
          margin-left: 20px;
        }
      }

      .chart-content {
        margin-top: 20px;

        canvas {
          width: 100% !important;
        }
      }
    }
  }

  .right {
    height: 100%;
    width: 408px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 10px;

    @media screen and (max-width: 1310px) {
      flex-direction: row;
      width: 100%;
      max-height: 500px;
    }

    .new-booking {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 370px;
      overflow: hidden;
      background-color: var(--dashboard-background);
      border-radius: 10px;
      padding: 10px;

      @media screen and (max-width: 1310px) {
        height: 100%;
      }

      .new-booking-title {
        color: var(--primary-text-color);
        font-size: 14px;
        font-weight: 700;

        span {
          font-size: 12px;
          font-weight: 500;
        }
      }

      .booking-list {
        width: 100%;
        overflow-y: auto;
        padding: 0 1px;

        .booking-card {
          background-color: var(--table-header-color-1);
          border-radius: 10px;
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          cursor: pointer;
          color: var(--secondary-text-color);

          .booking-card-contract-title {
            color: var(--table-text-color);
            width: 100%;
            padding: 0px 5px;
          }

          .booking-card-wrapper {
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 10px 5px;
            background-color: var(--background-3);
            border-radius: 10px;
            width: 100%;
            box-shadow: rgba(78, 78, 78, 0.5) 0px 3px 7px;
          }

          .booking-card-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 5px;
          }

          .icon {
            background-color: var(--primary-btn-color);
            max-height: 22px;
            max-width: 22px;
            height: 22px;
            width: 22px;
            border-radius: 11px;
            color: var(--primary-btn-text-color);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          }

          .booking-card-row-item {
            font-size: 12px;
            font-weight: 500;

            &.header {
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }
    }

    .chart-area {
      background-color: var(--dashboard-background);
      border-radius: 10px;
      padding: 10px;
      min-width: 250px;

      .chart-header {
        display: flex;
        flex-direction: row;
        align-items: center;

        .title {
          color: var(--primary-text-color);
          font-size: 18px;
          font-weight: 700;
        }

        .ant-picker {
          margin-left: 20px;
        }
      }

      .chart-content {
        margin-top: 20px;

        canvas {
          width: 100% !important;
        }
      }
    }
  }

  &-filter {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
  }
}

.dashboard-tasklist {
  margin-top: 24px;
  width: 100%;
  padding: 8px;

  .dashboard-tasklist-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .dashboard-tasklist-schedule {
    display: flex;
    align-items: flex-end;

    h6 {
      color: var(--primary-text-color);
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 0;
      margin-right: 12px;
    }
  }

  .list {
    margin-top: 12px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
  }

  .download-csv-btn {
    color: var(--primary-text-color);
    display: flex;
    justify-content: end;

    button {
      text-decoration: underline;
      background: inherit;
      border: none;
      outline: none;
      margin-top: 8px;
      padding: 0;
      a {
        color: var(--primary-text-color);
      }
    }
  }
}

.custom-toast.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: #feeaea;
  color: #4f4f4f;

  .Toastify__toast-icon.Toastify--animate-icon svg {
    fill: #e14b4b;
  }

  .Toastify__close-button svg {
    fill: #4f4f4f;
  }
}
