.setting-page-title {
  width: 100%;
  background-color: var(--background-1);
  border-radius: 5px 5px 0 0;
  font-size: 14px;
  font-family: 'Poppins-Medium';
  color: white;
  padding: 6px 15px;
}
.setting-page-info {
  background-color: var(--setting-background);

  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .setting-page-info-detail {
    flex: 1;
    padding: 0 8px;

    .panel {
      position: relative;
      border: 1px solid var(--secondary-text-color);
      padding: 20px 10px;
      border-radius: 10px;
      margin: 20px 0;
      color: var(--primary-text-color);

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .panel-title {
        position: absolute;
        top: -10px;
        background-color: var(--setting-background);
        font-size: 14px;
      }

      .group {
        flex: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;

        margin: 0 20px;

        .label-input {
          margin: 12px 0;

          &.input-password-area {
            .input-area {
              position: relative;
              width: 195px;

              input {
                width: 195px;
              }

              .input-password-show-btn {
                position: absolute;
                cursor: pointer;
                right: 10px;
                top: 8px;
                width: 20px;
                height: 15px;

                &.display {
                  top: 10px;
                  right: 10px;
                  width: 20px;
                  height: 11px;
                }
              }
            }
          }

          .company-setting-image {
            display: flex;
            align-items: center;

            .logo {
              height: 80px;
              border-radius: 5px;
              border: 0.1px solid var(--primary-text-color);

              img {
                width: auto;
                height: 100%;
              }
            }
          }

          .upload-btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: var(--primary-btn-text-color);
            font-size: 12px;
            font-family: 'Poppins-Medium';
            padding: 10px 3px;
            text-decoration: underline;
            margin-left: 20px;
            width: 140px;
            height: 32px;
            border-radius: 5px;
            background-color: var(--primary-btn-color);
            img {
              margin-right: 10px;
            }
          }
        }

        button {
          width: 200px;
          margin-left: 170px;
          margin-top: 20px;
        }
      }

      .label-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 12px 20px;
        flex: 1;

        &.avatar {
          align-items: flex-start;
        }

        label {
          min-width: 150px;
          font-size: 14px;
          font-family: 'Poppins-Medium';
        }

        input {
          width: 300px;
          height: 30px;
          border-radius: 5px;
          background-color: var(--background-3);
          color: black;
          font-size: 12px;
          font-family: 'Poppins-Regular';

          &:disabled {
            background-color: var(--primary-disable-color);
          }
        }

        .avatar {
          width: 80px;
          height: 80px;
          border-radius: 5px;
        }
      }

      &.change-password {
        .group {
          width: 100%;
          flex-direction: row;

          .label-input {
            margin-right: 20px;
          }
        }
        .change-password-btn {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          width: 100%;
          padding: 0 30px;
          font-size: 13px;
          font-family: 'Poppins-Medium';
          button {
            background-color: var(--primary-btn-color);
            color: var(--primary-btn-text-color);
            border: 1px solid var(--secondary-text-color);
            height: 30px;
            height: 150;
            border-radius: 5px;
            cursor: pointer;

            &:disabled {
              color: var(--secondary-text-color);
              background-color: inherit;
              border: none;
              cursor: auto;
            }
          }
        }
      }

      .change-profile-btn,
      .change-company-infor-btn {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        button {
          background-color: var(--primary-btn-color);
          color: var(--primary-btn-text-color);
          border: 1px solid var(--secondary-text-color);
          height: 30px;
          height: 150;
          border-radius: 5px;
          cursor: pointer;

          &:disabled {
            color: var(--secondary-text-color);
            background-color: inherit;
            border: none;
            cursor: auto;
          }
        }
      }
    }
  }

  .setting-page-logout {
    width: 100%;
    padding: 34px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    button {
      background-color: var(--primary-btn-color);
      color: var(--primary-btn-text-color);
      height: 33px;
      width: 170px;
      border: none;
      border-radius: 5px;
      margin-top: 27px;
      font-size: 13px;
      font-family: 'Poppins-Medium';
    }
  }

  .input-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
